<template>
    <div>
    <v-row>
          <v-col cols="12">
              <v-card :loading="isLoading" outlined style="width:100%;">
              
                  <v-card-title>New Deal Details</v-card-title>
                      <v-card-subtitle class="font-weight-light caption">Deal Details</v-card-subtitle>
                      <v-divider></v-divider>
                <v-row class="px-5 mt-2">
                  <v-col cols="12">
                    <v-select
            :items="Types"
            v-model="Offer.Offer_Type"
            item-text="Title_English"
            item-value="id"
            label="Offer Type"
            outlined
           
          ></v-select>
                  </v-col>
                 <v-col cols="12">
                  <h1> <v-text-field
            v-model="Offer.CAP"
            outlined
            label="OFFER CAP"
            
          ></v-text-field></h1>
                 </v-col>
                 <v-col
        cols="12"
       
      >
        <v-dialog
          ref="dialog"
          v-model="pickermodel"
          :return-value.sync="Offer.Expiry_Date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="Offer.Expiry_Date"
              label="Expiry_Date"
              
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="Offer.Expiry_Date"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="pickermodel = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(Offer.Expiry_Date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
                 <v-col cols="12">
                  <v-textarea
                  outlined
            name="input-7-4"
            v-model="Offer.Details_Arabic"
            label="Deal Terms And Conditions / Arabic   "
            value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
          ></v-textarea> 
                 </v-col>
                </v-row>
                </v-card>
                </v-col>
                </v-row>  
  
                <v-row>
                </v-row>
                <v-row class="mt-15">
                  <v-col cols="12">
                  <v-card @click="SubmitOffer" :loading="isLoading"  ripple dark tail color="primary" >
                    <v-row class="pa-4">
                    
                      <v-col cols="auto">
                        <v-card-title class="white--text text-h4">Send For Review  <v-icon class="mx-4" large>mdi-send-circle-outline</v-icon></v-card-title>
                      <v-card-subtitle class="font-weight-light subtitle-1 white--text">Your Deal Will Be Sent To be Reviewd By Our Team Before its Published</v-card-subtitle>
                     
                      </v-col>
                     
                    </v-row>
                      
                     
                    
  
                  </v-card>
              </v-col>
                </v-row>
                <v-navigation-drawer
      v-model="SelectChannelModel"
        absolute
        
        temporary
        
        :right="!$vuetify.rtl"
        :left="$vuetify.rtl"
        style="width:50%"
      >
                      <v-card-title>Channels</v-card-title>
                      <v-card-subtitle class="font-weight-light caption">Select The Channels Where Your Deal Will Be Published </v-card-subtitle>
  
        <v-divider></v-divider>
        <v-card @click="CloseModel" :loading="isLoading"  ripple dark tail color="primary" >
                    <v-row class="pa-2">
                      
                      <v-col cols="auto">
                        <v-card-title class="white--text text-h6">Click Here when ready </v-card-title>
                      <v-card-subtitle class="font-weight-light subtitle-1 white--text">Select the logos to where you deal will be published</v-card-subtitle>
                     
                      </v-col>
                      
                    </v-row>
                      
                     
                    
  
                  </v-card>
                  <v-card class="mt-15 pa-5" outlined>
                    <v-container class="pa-2">
        <v-item-group v-model="Offer.Selected_Channels" multiple>
    
        <v-row >
          <v-col
          v-for="n in Channels"
            
            
            xl="4"
            lg="6"
            md="6"
          >
          <div class="pa">
            <v-item  :value="n" v-slot="{ active, toggle }">
            <v-card  @click="toggle" outlined class="pa-5"> 
              <v-row justify="center">
                <v-col cols="auto">
                  <v-avatar
                :color="active ? 'primary' : ''"
                class="d-flex align-center"
                dark
                flat
                
                ripple
                size="100"
               
              >
              <v-img v-if="!active" :src="'https://1mj7f7en.directus.app/assets/'+n.logo"></v-img>
                
       
                  <div
                    v-if="active"
                    class="overline white--text flex-grow-1 text-center"
                  >
                    {{n.Title_English}}
                  </div>
            
              </v-avatar>
                </v-col>
              </v-row>
              <v-card-title class="flex-grow-1 text-center">{{n.Title_English}}</v-card-title>
            </v-card>
              
            </v-item>
          </div>
          </v-col>
          
        </v-row>
     
        </v-item-group>
      </v-container>
      </v-card>
      </v-navigation-drawer>
  
                
   
        
    
    </div>
  </template>
  <script>
  export default {
    data:function(){
      return {
        Types:[],
        modal:false,
        pickermodel:false,
        isLoading:false,
        isError:false,
        Channels:[],
        date:null,
        SelectChannelModel:false,
        Offer:{
          CAP:0,
        Offer_Type:null,
        Expiry_Date:null,
        Details_Arabic:'',
        Template:"ATD",
        Selected_Channels:[],
      }
      }
    },
    methods:{
      CloseModel:function(){
        //console.log('closing')
        this.$forceUpdate(); 
        this.SelectChannelModel=false;
        console.log(this.Offer.Selected_Channels)
      },
      getOffersType:async function(){
        let res = await this.$directus.items('Offer_types').readByQuery({
                  limit: -1,
                  
              });
            this.Types=res.data
            console.log(this.Types)
      },
      getChannels:async function(){
        let res = await this.$directus.items('Channels').readByQuery({
                  limit: -1,
                  
              });
            this.Channels=res.data
            console.log(this.Types)
      },
      SubmitOffer:async function(){
        this.isLoading=true;
        this.isError=false;
        var User = await this.$directus.users.me.read();
        let Org = JSON.parse(window.localStorage.getItem('Org'));
      
        const res = this.Offer.Selected_Channels.map(function(el){
          return {Channels_id:el.id};
        })
        
        try{
          await this.$directus.items('offer_test').createOne({
            CAP:this.Offer.CAP,
            Details_Arabic:this.Offer.Details_Arabic,
            Offer_Type:this.Offer.Offer_Type,
            Expiry_Date:this.Offer.Expiry_Date,
            Template:"TBD",
            Merchent_ID:{
              id:Org.id,
              Owner:{
                id: User.id
              }
            }
            
           
                  
            
        
           
          
  
        });
        this.Rest();
        this.isLoading=false;
        this.$emit("reload")
        }catch(e){
          this.isLoading=false;
          this.isError=true
        }
       
  
      },
      Rest:function(){
        this.Offer={
          Offer_Type:null,
        Expiry_Date:null,
        Selected_Channels:[],
      }
      }
    },
      mounted:function(){
       
      
       
         this.getOffersType();
         this.getChannels()
      }
  }
  </script>