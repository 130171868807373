import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/Login/Login.vue'
import SettingsView from '../views/Settings/Settings.vue'
import RedeemsView from '../views/Redeems/Redeems.vue'
import CashierView from '../views/Settings/Views/Cashier.vue'
import AccountView from '../views/Settings/Views/Account.vue'
import ATDview from  '../views/Deals/Views/ATD.vue'
import TBDView from  '../views/Deals/Views/TBD.vue'
import DealView from '../views/Deal/Deal.vue'
import SchoolView from '../views/school/school.vue'
import { Directus } from '@directus/sdk';

import DealsDash from '../views/Deals/Deals.vue'
const directus = new Directus('https://1mj7f7en.directus.app');

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {
      hideForAuth: true,
   },
  },
  {
    path: '/school/:id',
    name: 'deal',
    component: SchoolView,
    meta: {
      requiresAuth: true,
   },
  },
  {
    path: '/deal/:id',
    name: 'deal',
    component: DealView,
    meta: {
      requiresAuth: true,
   },
  },
  {
    path: '/deals',
    name: 'deals',
    component: DealsDash,
    meta: {
      requiresAuth: true,
   },
  },
  {
    path: '/deals/ATD',
    name: 'deals',
    component: ATDview,
    meta: {
      requiresAuth: true,
   },
  },
  {
    path: '/deals/TBD',
    name: 'deals',
    component: TBDView,
    meta: {
      requiresAuth: true,
   },
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      hideForAuth: true,
   },
  },
  
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true,
   },
   
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: SettingsView,
    meta: {
      requiresAuth: true,
   },
   
  },
  {
    path: '/Settings/Cashier',
    name: 'Cashier',
    component: CashierView,
    meta: {
      requiresAuth: true,
   },
   
  },
  {
    path: '/Settings/Account',
    name: 'Cashier',
    component: AccountView,
    meta: {
      requiresAuth: true,
   },
   
  },
  {
    path: '/Redeems',
    name: 'Redeems',
    component: RedeemsView,
    meta: {
      requiresAuth: true,
   },
   
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  let token = window.localStorage.getItem('auth_token')
 
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (token !== null && token !== undefined) {
      next()
      return
    }
    next('/login')
  }else
  {
    next()
  }
  


  if (to.matched.some(record => record.meta.hideForAuth)) {
    console.log(token)
    if (token) {
        next('/home');
        return
    } else {
        next();
    }
} else {
    next();
}
  
})

export default router
