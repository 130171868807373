<template>
<v-container>
    <v-row dense class=" py-5 px-15" justify="space-between">
                        <h1 class=" text-h5 font-weight-black">Settings</h1>
                        <v-spacer></v-spacer> 
                       
                    
                   </v-row>
                   <v-divider></v-divider>
    <v-row justify="center" class="mt-5">
        <v-col cols="4">
            <v-card outlined>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="70">
                            <v-icon large>mdi-store-outline</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Merchant Account</v-card-title>
                <v-card-subtitle>Update Your Merchant Account Information </v-card-subtitle>
                <v-card-actions>
                    <v-btn block flat elevation="0" dark color="black" to="/Settings/Account"> View</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card outlined disabled>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="70">
                            <v-icon large>mdi-account-alert-outline</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>User Accounts</v-card-title>
                <v-card-subtitle>Add / Update User Information</v-card-subtitle>
                <v-card-actions>
                    <v-btn block  disabled> View</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card outlined>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="70">
                            <v-icon large>mdi-network-pos</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Cashier Management </v-card-title>
                <v-card-subtitle>Add / Remove Cashier Accounts On Telegram </v-card-subtitle>
                <v-card-actions>
                    <v-btn block   flat elevation="0" dark color="black" to="/Settings/Cashier"> View</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <v-row justify="center">
        <v-col cols="4">
            <v-card outlined disabled>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="70">
                            <v-icon large>mdi-wallet-plus-outline</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Cash Back Programs</v-card-title>
                <v-card-subtitle>Manage Your Cash Back Programs </v-card-subtitle>
                <v-card-actions>
                    <v-btn block  outlined disabled> View</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card outlined disabled>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="70">
                            <v-icon large>mdi-database-alert</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Data Analytics</v-card-title>
                <v-card-subtitle>Upgrade Your Account To Unlock More Insights</v-card-subtitle>
                <v-card-actions>
                    <v-btn block  outlined disabled> View</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
        <v-col cols="4">
            <v-card outlined disabled>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="70">
                            <v-icon large>mdi-account-edit</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Remove Account </v-card-title>
                <v-card-subtitle>Opt out From Neo Engine </v-card-subtitle>
                <v-card-actions>
                    <v-btn block outlined disabled> View</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    
   
</v-container>
</template>

<script>
export default {
    mounted: async function () {
        await this.GetCods()
        this.getCashiers()
    },
    methods: {
        updateMerchantInfo:async function(){
            try{
                this.isLoading=true;
                await this.$directus.items('Merchant').updateOne(this.Merchant.id, this.Merchant);
                await this.GetCods();
                this.isLoading=false;
            }catch(e){
                console.log(e)
            }
          
        },
        DelCashier:async function(id){
            this.isCashierLoading=true;
            let res = await this.$directus.items('Cashier').deleteOne(id);
            this.isCashierLoading=false;
            this.getCashiers()
        },
        getCashiers:async function(){
            this.isCashierLoading=true;
            try{
                let res = await this.$directus.items('Cashier').readByQuery({
                // By default API limits results to 100.
                // With -1, it will return all results, but it may lead to performance degradation
                // for large result sets.
                sort:['-date_created'],
                filter: {
                    Merchant: {

                       "_eq": this.Merchant.id
                    
                    }
                },
            });
        this.isCashierLoading=false;
        
       this.Cashiers = res.data
            }catch(e){
                console.log(e);
                this.isCashierLoading=false;
            }
            
        },
        restCashier:function(){
            this.Cashier={
                Name:"",
                Username:""
            }
        },
        SaveCashier:async function(){
            console.log("Test")
            this.isCashierLoading=true;
            let res = await this.$directus.items('Cashier').createOne({
	            Name: this.Cashier.Name,
                username:this.Cashier.Username,
                Merchant:{
                    id:this.Merchant.id
                }
        });
        this.isCashierLoading=false;
        this.restCashier();
        this.getCashiers()
        console.log(res)
        },
        GetCods: async function () {
            this.isLoading = true;
            let user = await this.$directus.users.me.read();
            let res = await this.$directus.items('Merchant').readByQuery({
                // By default API limits results to 100.
                // With -1, it will return all results, but it may lead to performance degradation
                // for large result sets.
                filter: {
                    Owner: {

                        "_eq": user.id

                    }
                },
            });
            this.Merchant = res.data[0];
            this.isLoading = false;
            console.log(res.data[0]);
        }
    },
    data: function () {
        return {
            isCashierLoading:false,
            isLoading: true,
            Merchant: {},
            Cashiers:[],
            Cashier:{
                Name:"",
                Username:""
            }
        }
    }
}
</script>
