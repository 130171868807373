<template>
<v-container>
    <v-row justify="start">
        <v-col cols="auto">
            <h1>Account Settings</h1>
            
        </v-col>
        
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center" class="mt-5">
        <v-col cols="12" xl="8" lg="10">
            <v-card :loading="isLoading" outlined>
                <div class="pa-5">
                    <v-row justify="center">
                        <v-col cols="auto">
                            <v-avatar :loading="isLoading" size="150" color="grey">
                                <v-img cover :src="'https://1mj7f7en.directus.app/assets/'+Merchant.Logo"></v-img>
                            </v-avatar>
                        </v-col>
                    </v-row>
                    <v-row dense class="py-1 px-2">
                        <v-col cols="12">
                            <v-text-field :loading="isLoading" v-model="Merchant.Title_English" label="Title English" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="py-1 px-2">
                        <v-col cols="12">
                            <v-text-field :loading="isLoading" v-model="Merchant.Title_Arabic" label="Title Arabic" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="py-1 px-2">
                        <v-col cols="12">
                            <v-text-field :loading="isLoading" prefix="+962" label="Phone Number" v-model="Merchant.Phone_Number" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense class="py-1 px-2">
                        <v-col cols="12">
                            <v-text-field :loading="isLoading" label="Map Link" v-model="Merchant.Map" outlined></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- <v-row dense class="py-1 px-2">
                        <v-col cols="12">
                            <v-file-input accept="image/*" label="Logo" outlined :prepend-icon="false"></v-file-input>
                        </v-col>
                    </v-row> -->
                    <v-row justify="center">
                        <v-col cols="5">
                            <v-btn block :loading="isLoading" dark x-large @click="updateMerchantInfo" flat>Update</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
    mounted: async function () {
        await this.GetCods()
        this.getCashiers()
    },
    methods: {
        updateMerchantInfo:async function(){
            try{
                this.isLoading=true;
                await this.$directus.items('Merchant').updateOne(this.Merchant.id, this.Merchant);
                await this.GetCods();
                this.isLoading=false;
            }catch(e){
                console.log(e)
            }
          
        },
        
        GetCods: async function () {
            this.isLoading = true;
            let user = await this.$directus.users.me.read();
            let res = await this.$directus.items('Merchant').readByQuery({
                // By default API limits results to 100.
                // With -1, it will return all results, but it may lead to performance degradation
                // for large result sets.
                filter: {
                    Owner: {

                        "_eq": user.id

                    }
                },
            });
            this.Merchant = res.data[0];
            this.isLoading = false;
            console.log(res.data[0]);
        }
    },
    data: function () {
        return {
            isCashierLoading:false,
            isLoading: true,
            Merchant: {},
            Cashiers:[],
            Cashier:{
                Name:"",
                Username:""
            }
        }
    }
}
</script>