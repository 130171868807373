<template>
<v-app>

    <div v-if="!isLoading">
        <v-app-bar :dense="!$vuetify.breakpoint.xl" v-if="!$route.meta.hideForAuth" :clipped-left="!this.$vuetify.rtl" :clipped-right="this.$vuetify.rtl" :right="$vuetify.rtl" app flat color="primary" permanent>
            <v-app-bar-title class="white--text text-h5 font-weight-light">Neo Engine</v-app-bar-title>
            <v-spacer></v-spacer>
            <v-btn @click="changeLang" dark text>{{this.$vuetify.lang.current === 'en-US' ? 'عربي' : 'English'}}</v-btn>
        </v-app-bar>

        <v-navigation-drawer :right="$vuetify.rtl" v-if="!$route.meta.hideForAuth" clipped app permanent width="200" style="max-width: 300px;">
            <v-list-item>
                <v-list-item-avatar>
                    <v-avatar size="50">
                        <v-img :src="'https://1mj7f7en.directus.app/assets/'+Org.Logo"></v-img>
                    </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title class="text-h6">
                        {{User.first_name}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{Org.Title_English}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list class="px-5 mt-5" nav rounded>
                <v-list-item :dense="!$vuetify.breakpoint.xl" to="/home" link>
                    <v-list-item-icon>
                        <v-icon>mdi-home-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Home</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :dense="!$vuetify.breakpoint.xl" to="/deals" link>
                    <v-list-item-icon>
                        <v-icon>mdi-sale-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Deals</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :dense="!$vuetify.breakpoint.xl" disabled>
                    <v-list-item-icon>
                        <v-icon>mdi-square-rounded-badge-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Content</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :dense="!$vuetify.breakpoint.xl" to="/Redeems" link>
                    <v-list-item-icon>
                        <v-icon>mdi-star-shooting-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Redeems</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :dense="!$vuetify.breakpoint.xl" to="/Settings" link>
                    <v-list-item-icon>
                        <v-icon>mdi-shield-account-outline</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Settings</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :dense="!$vuetify.breakpoint.xl" @click="Logout()" link>
                    <v-list-item-icon>

                        <v-icon>mdi-account-arrow-right-outline</v-icon>

                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

        </v-navigation-drawer>

        <v-main app>
            <v-container fluid>
                <router-view></router-view>
            </v-container>
        </v-main>

        <!-- If using vue-router -->
    </div>
    <div v-if="isLoading">
        <br /><br /><br /><br /><br /><br /><br /><br />
        <v-row justify="center" align="center">

            <v-col cols="auto">
                <h1 class=" center-text text-xl-h1 text-h4 font-weight-thin">Neo Engine</h1>

            </v-col>
        </v-row>
        <v-row justify="center" align="center">

            <v-col cols="auto">
                <v-progress-circular indeterminate size="50" color="primary"></v-progress-circular>

            </v-col>

        </v-row>
    </div>

</v-app>
</template>

<script>
export default {
    name: 'App',
    methods: {
        changeLang: function () {
            if (this.$vuetify.lang.current === 'en-US') {
                this.$vuetify.lang.current = 'ar';
                this.$vuetify.rtl = true;
            } else {
                this.$vuetify.lang.current = 'en-US';
                this.$vuetify.rtl = false;
            }

        },
        Logout: async function () {

            window.localStorage.removeItem('Token')

            await this.$directus.auth.logout()

            window.location.reload();
        },
    },
    created: async function () {
        let token = window.localStorage.getItem('auth_token')
        if (token) {
            this.isLoading = true
            this.User = await this.$directus.users.me.read();
            let res = await this.$directus.items('Merchant').readByQuery({
                filter: {
                    Owner: {
                        id: {
                            _eq: this.User.id
                        }
                    }
                }
            });
            this.Org = res.data[0]
            window.localStorage.setItem('Org', JSON.stringify(res.data[0]))
            this.isLoading = false;
        }
    },
    data: () => ({
        User: {},
        Org: {},
        isLoading: false
    }),
};
</script>
