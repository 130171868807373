<template>

    
        <v-container fluid class="pa-0" > 
            <v-row style="height:100vh;background-color: #121212;">
                <v-col  xl="3" lg="4" md="6" >
                    <v-row class="mt-xl-15 mx-3 my-lg-10"  >
                        <v-col cols="2">
                            <v-avatar class="mt-2" color="black">
                                <v-img src="https://firebasestorage.googleapis.com/v0/b/adzplace-demo.appspot.com/o/Neo%20engine%20(1).png?alt=media&token=5895d058-380d-4eb2-aede-8840a92b06e5"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col cols="8" class="mt-2">
                           <h3 class="font-weight-black white--text" >Neo Engine</h3>
                           <h4 class="font-weight-light white--text">Partner Portal</h4>
                        </v-col>
                    </v-row>
                    <div style="height:5vh" v-if="$vuetify.breakpoint.xl">
                    
                    </div>
                    <v-row   class="mx-3 mt-xl-15">
                        <v-col cols="auto">
                            <h1 class="text-xl-h4 text-lg-h5 font-weight-black white--text">Sign In</h1>
                        </v-col>
                    </v-row>
                    <v-row dense class="mx-5 mt-10">
                        <v-col cols="12">
                            <v-text-field
            label="Email"
            v-model="email"
            outlined
            class="white--text"
            dark
            :dense="!$vuetify.breakpoint.xl"
          ></v-text-field>  
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
            label="Password"
            v-model="password"
            type="password"
            outlined
            dark
            :dense="!$vuetify.breakpoint.xl"
          ></v-text-field>  
                        </v-col>
                    </v-row>
                    <v-row dense class="mx-5 ">
                        <v-col cols="12">
                            <v-btn block  :x-large="$vuetify.breakpoint.xl"   elevation="0" @click="Login()" :loading="Loading"  color="white">Sign In</v-btn>
                        </v-col>
                    </v-row>
                    <v-row dense class="mx-10 mt-5">
                        <v-col cols="12">
                            <v-btn block disabled text :x-large="$vuetify.breakpoint.xl"   class="white--text" dark color="black">Register</v-btn>
                        </v-col>
                    </v-row>
                    <!-- <v-row dense class="mx-10 mt-5">
                        <v-col cols="12">
                            <v-btn block text x-small @click="changeLang"  class="white--text" dark color="black">{{this.$vuetify.lang.current === 'en-US' ? 'عربي' : 'English'}}</v-btn>
                        </v-col>
                    </v-row> -->
                    <v-row justify="center" v-if="isErrorEmail">
                        <v-col cols="10">
                            <v-alert
  border="right"
  color="red"
  type="error"
  :dense="!$vuetify.breakpoint.xl"
> Wrong Email/Password</v-alert>
                    </v-col>
                    </v-row>
                </v-col>
                <v-col xl="9" lg="8" md="6"  >
                    <v-row justify="center">
                        <v-col cols="12">
                    <lottie-player src="https://assets8.lottiefiles.com/private_files/lf30_02krsulp.json"  background="transparent"  speed="1.5"  style="height: 100vh;"  loop  autoplay></lottie-player>
                </v-col>
                </v-row>
                </v-col>
            </v-row>
    </v-container>
 

</template>
<script>
export default {
data(){
    return {
        email:"",
        password:"",
        Loading:false,
        isErrorEmail:false,
        isErrorNetwork:false
    }
},
methods:{
    changeLang:function(){
        if(this.$vuetify.lang.current === 'en-US'){
            this.$vuetify.lang.current = 'ar';
            this.$vuetify.rtl = true;
        }else{
            this.$vuetify.lang.current = 'en-US';
            this.$vuetify.rtl = false;
        }
       
    },
    Login:async function (){
        let self=this;
        this.Loading=true;
        this.isErrorNetwork=false;
        this.isErrorEmail=false;
        try{
            console.log(self)
         const res=   await self.$directus.auth.login({
	email: this.email,
	password: this.password,
});
this.Loading=false;
window.location.reload();

console.log(res)
        } catch (e) {
            this.Loading=false;
            this.isErrorEmail=true;
            this.email="";
            this.password="";
            
        }
        
    }
}
}
</script>