<template>
<v-container>
    <v-row justify="start">
        <v-col cols="auto">
            <h1>Create An All Time Deal</h1>
            
        </v-col>
        
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center" class="mt-5">
        <v-col cols="10">
            <new-deal></new-deal>
        </v-col>

</v-row>
</v-container>
</template>
<script>
import NewDeal from '../NewDeal.vue'
export default{
components:{
    newDeal:NewDeal
}
}
</script>