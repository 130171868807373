<template>
<v-container>
    <v-row justify="center" v-if="isLoading">
        <v-col cols="10">
            <v-card outlined>             
                     <v-row justify="start">
                        <v-col cols="3">  <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader></v-col>
                      
                     </v-row>
                     <v-row dense justify="start">
                        <v-col cols="3">  <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader></v-col>
                      
                     </v-row>
              
                     <v-row>
                        <v-col cols="6">
                        <v-card-title style="width:100%"><v-skeleton-loader style="width:70%" type="text"></v-skeleton-loader></v-card-title>
                        <v-card-subtitle style="width:100%"><v-skeleton-loader style="width:70%;" class="mt-2"  type="text"></v-skeleton-loader></v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                        <v-card-title style="width:100%"><v-skeleton-loader style="width:70%" type="text"></v-skeleton-loader></v-card-title>
                        <v-card-subtitle style="width:100%"><v-skeleton-loader style="width:70%;" class="mt-2"  type="text"></v-skeleton-loader></v-card-subtitle>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="6">
                        <v-card-title style="width:100%"><v-skeleton-loader style="width:70%" type="text"></v-skeleton-loader></v-card-title>
                        <v-card-subtitle style="width:100%"><v-skeleton-loader style="width:70%;" class="mt-2"  type="text"></v-skeleton-loader></v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                        <v-card-title style="width:100%"><v-skeleton-loader style="width:70%" type="text"></v-skeleton-loader></v-card-title>
                        <v-card-subtitle style="width:100%"><v-skeleton-loader style="width:70%;" class="mt-2"  type="text"></v-skeleton-loader></v-card-subtitle>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="6">
                        <v-card-title style="width:100%"><v-skeleton-loader style="width:70%" type="text"></v-skeleton-loader></v-card-title>
                        <v-card-subtitle style="width:100%"><v-skeleton-loader style="width:70%;" class="mt-2"  type="text"></v-skeleton-loader></v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                        <v-card-title style="width:100%"><v-skeleton-loader style="width:70%" type="text"></v-skeleton-loader></v-card-title>
                        <v-card-subtitle style="width:100%"><v-skeleton-loader style="width:70%;" class="mt-2"  type="text"></v-skeleton-loader></v-card-subtitle>
                        </v-col>
                     </v-row>
                    

            </v-card>
        </v-col>
    </v-row>
    <v-row justify="center" v-if="!isLoading">
        <v-col cols="10">
            <v-card outlined>             
                     <v-row justify="start">
                        <v-col cols="3">
                            <v-card-title style="width:100%">Deal #{{ 2000 + parseInt(Data.id) }}</v-card-title>
                        <v-card-subtitle style="width:100%">Deal Ref Number </v-card-subtitle>
                        </v-col>
                      
                     </v-row>
                                <v-divider></v-divider>
                     <v-row>
                        <v-col cols="6">
                        <v-card-title style="width:100%">{{Data.Offer_Type.Title_English  }}</v-card-title>
                        <v-card-subtitle style="width:100%">Deal</v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                        <v-card-title style="width:100%" :style="{color:Data.status === 'draft' ? 'orange' : 'green'}"> {{ Data.status === 'draft' ? 'Pending' : 'Published' }}</v-card-title>
                        <v-card-subtitle style="width:100%">Status</v-card-subtitle>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="6">
                        <v-card-title style="width:100%">{{new Date(Data.Expiry_Date).toLocaleDateString()  }}</v-card-title>
                        <v-card-subtitle style="width:100%">Expiry Date</v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                        <v-card-title style="width:100%">{{new Date(Data.date_created).toLocaleDateString()  }}</v-card-title>
                        <v-card-subtitle style="width:100%">Created Date</v-card-subtitle>
                        </v-col>
                     </v-row>
                     <v-row>
                        <v-col cols="6">
                        <v-card-title style="width:100%">{{Data.CAP }}</v-card-title>
                        <v-card-subtitle style="width:100%">CAP</v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                        <v-card-title style="width:100%">1</v-card-title>
                        <v-card-subtitle style="width:100%">Redeemed</v-card-subtitle>
                        </v-col>
                     </v-row>
                    

            </v-card>
        </v-col>
    </v-row>
    <v-row class="my-5" justify="center">
                  <v-col cols="10">
                  <v-card @click="DeleteDeal()" :loading="isLoading"  :disabled="Data.status === 'draft'" ripple dark tail color="red" >
                    <v-row class="pa-4">
                    
                      <v-col cols="auto">
                        <v-card-title class="white--text text-h4">Unpublish Deal  <v-icon class="mx-4" large>mdi-send-circle-outline</v-icon></v-card-title>
                      <v-card-subtitle class="font-weight-light subtitle-1 white--text">Your Deal Will Be Unpublished From All Channels , this will not delete your deal information </v-card-subtitle>
                     
                      </v-col>
                     
                    </v-row>
                      
                     
                    
  
                  </v-card>
              </v-col>
                </v-row>
                <v-row justify="center" >
        <v-col cols="10">
            <v-card outlined>             
                <v-row dense class=" py-5 px-15" justify="space-between">
                        <h1 class=" text-h5 font-weight-black">Latest Redeems</h1>
                        <v-spacer></v-spacer> 
                        <v-btn  icon outlined :disabled="Page===1"  @click="CodePageDown" class="mx-1"><v-icon>mdi-chevron-left</v-icon></v-btn >
                            <v-btn  icon outlined :disabled="!desserts.length"  @click="CodePageUP" class="mx-1"><v-icon>mdi-chevron-right</v-icon></v-btn >
                    
                   </v-row>
                                <v-divider></v-divider>
                                <v-row justify="center" class="my-5" v-if="isLoading">
        <v-col cols="11" v-for="n in 5">
            <v-card  outlined class="pa-5" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>
                                <v-row justify="center" class="my-5" v-if="desserts.length && !isLoading">
        <v-col cols="11" v-for="n in desserts">
            <v-card  outlined class="pa-5" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title" >#{{2500 + n.id}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Number</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title">{{n.Code}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Code</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light overline " >{{n.Offer_ID.Offer_Type ? n.Offer_ID.Offer_Type.Title_English : '#'  }}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Offer</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light caption">{{n.Channel ? n.Channel.Title_English : '#' }}</v-card-title>
                        <v-card-subtitle class="font-weight-light title">Channel</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title">{{new Date(n.date_created).toLocaleDateString()}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Date</v-card-subtitle>
                    </v-col>
                    
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>
    <v-row justify="center" class="my-5" v-if="isEnd">
        <v-col cols="auto">
            <h1>No More Redeems</h1>
        </v-col>
    </v-row>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>

export default{
data:function(){
    return {
        isLoading : false,
        Data:{},
        Page:1,
        isEnd:false,
        desserts:[]
    }
},
mounted:async function(){
     this.Data = await this.getDeals();
     await this.GetCods()
},
methods:{
    CodePageUP:async function (){
            this.Page++;
            this.GetCods();
        },
        CodePageDown:async function (){
            this.Page--;
            this.GetCods();
        },
        GetCods:async function(){
            this.isLoading=true;
            this.isEnd=false;
            let res = await this.$directus.items('Merchant_Code').readByQuery({
             filter:{
                Offer_ID :{
                    id : {
                        _eq:this.$route.params.id
                    }
                }
             },
                limit: 10,
                page:this.Page,
                fields:['id','date_created','Code','Channel.id.Title_English','Channel.*','Redeemed','Offer_ID.Offer_Type.*','Offer_ID.id'],
                sort: ['-date_created']
            });
            this.desserts=res.data;
            if(!res.data.length)
            this.isEnd=true;
            this.isLoading=false;
            console.log(res.data)
        },
    getDeals: async function () {
           this.isLoading=true;
            let res = await this.$directus.items('offer_test').readByQuery({
                // By default API limits results to 100.
                // With -1, it will return all results, but it may lead to performance degradation
                // for large result sets.
                filter:{
               
               id:{
                   _eq:this.$route.params.id
              
           }
          },
                
               
                fields: ['id', 'date_created', 'Offer_Type.Title_English', 'Offer_Type.Title_Arabic','Template', 'Expiry_Date', 'status','CAP', 'Selected_Channels'],
               
            });
            this.isLoading=false;
            return res.data[0]
            
           
                 },
}
}
</script>