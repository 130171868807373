<template>
<v-container>
    <v-sheet style="height:30vh" dark @click="" v-if="!isLoading">
    <v-row style="height:100%" class="px-10" justify="center" align="center">
        <v-col cols="10">
            <v-list dark>
                <v-list-item x-large two-line>
                <v-list-item-avatar size="150">
                    <v-img fill aspect-ratio="2" :src="'https://1mj7f7en.directus.app/assets/'+Data.logo"  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                                        <v-list-item-title class="text-h4 font-weight-bold text-capitalize ">{{ Data.Title_English }} </v-list-item-title>
                                        <v-list-item-subtitle class="text-h6 mt-1">Locked <v-icon small >mdi-lock</v-icon></v-list-item-subtitle>
                                        <v-list-item-subtitle class="mt-5"><v-btn disabled>Add A Deal</v-btn> <v-btn disabled>Send A Message</v-btn></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
    </v-sheet>
    <v-sheet style="height:30vh" dark @click="" v-if="isLoading">
    <v-row style="height:100%" class="px-10" justify="center" align="center">
        <v-col cols="10">
            <v-list dark>
                <v-list-item x-large two-line>
                <v-list-item-avatar color="white" size="150">
                   
                </v-list-item-avatar>
                <v-list-item-content>
                                        <v-list-item-title class="text-h4 font-weight-bold text-capitalize "><v-skeleton-loader  max-width="300" type="text"></v-skeleton-loader></v-list-item-title>
                                        <v-list-item-subtitle class="text-h6 mt-1">Locked <v-icon small >mdi-lock</v-icon></v-list-item-subtitle>
                                        <v-list-item-subtitle class="mt-5"><v-btn disabled loading>Add A Deal</v-btn></v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
    </v-sheet>

    <v-row class="mt-5" justify="center">
        <v-col cols="6">
            <v-card outlined class="pa-5" >
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar size="80" color="black">
                            <v-icon color="white" large>mdi-star-check</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="auto">
                        <h1 class="font-weight-light">Deals</h1>
                    </v-col>
                </v-row>
                <v-card-text></v-card-text>
            </v-card>
        </v-col>
        <v-col cols="6">
            <v-card outlined class="pa-5" >
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar size="80" color="orange">
                            <v-icon large color="white">mdi-star-shooting-outline</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="auto">
                        <h1 class="font-weight-light">Redeems</h1>
                    </v-col>
                </v-row>
                <v-card-text></v-card-text>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
data:function(){
    return{
        Data:{},
        isLoading:true,
    }
},
mounted:async function (){
    this.getChannels()
},
methods:{
    
    getChannels: async function () {
     this.isLoading=true;
let res = await this.$directus.items('Channels').readByQuery({
    
    filter:{
        id:{
            _eq:this.$route.params.id
        }
    },
    
});
this.Data = res.data[0];
this.isLoading=false; 
console.log(res.data)

}
}
}
</script>