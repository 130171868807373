<template>
<v-container>
    <v-row justify="start">
        <v-col cols="auto">
            <h1>Cashiers</h1>
            
        </v-col>
        
    </v-row>
    <v-divider></v-divider>

    <v-row class="mt-5" justify="center">
      <v-col cols="4">
        <v-card outlined>
            <v-card-title>Add A New Cashier</v-card-title>
            <v-card-subtitle>Connect Cashier Over Telegram </v-card-subtitle>
            <v-divider></v-divider>
            <v-card-text>
                <v-row >
                          <v-col cols="12">
                                        <v-text-field v-model="Cashier.Name" label="Name" outlined></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row dense >
                                    <v-col cols="12">
                                        <v-text-field prefix="@" v-model="Cashier.Username" label="Telegram Username" outlined></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-card-subtitle>Telgram Link : <a href="https://t.me/Neoengine_bot" class="blue--text">https://t.me/Neoengine_bot</a> </v-card-subtitle>
            </v-card-text>
            <v-card-actions class="px-5 mb-2">
               
                                        <v-btn block color="black" dark :loading="isLoading" large @click="SaveCashier" >Add</v-btn>
                </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="start">
        <v-col cols="auto">
            <h1>Connected Cashiers</h1>
            
        </v-col>
        
    </v-row>
    <v-divider></v-divider>
    <v-row justify="center" class="my-5" v-if="!isCashierLoading">
        <v-col cols="12"  v-for="n in Cashiers">
            <v-card  outlined class="pa-5" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-card-title class="font-weight-medium subtitle-1" >{{n.Name}} </v-card-title>
                        <v-card-subtitle class="font-weight-light">Name</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-medium subtitle-1">@{{n.username}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">User Name</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-medium subtitle-1" >{{new Date(n.date_created).toLocaleDateString()}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Date Created</v-card-subtitle>
                    </v-col>
                  
                    <v-col cols="2">
                        <v-btn block color="red" :loading="isLoading" outlined dark large @click="DelCashier(n.id)" class="mt-5" >Delete</v-btn>
                    </v-col>
                    
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>
    <v-row justify="center" class="my-5" v-if="isCashierLoading">
        <v-col cols="11" v-for="n in 5">
            <v-card  outlined class="pa-5" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default {
    mounted: async function () {
        await this.GetCods()
        this.getCashiers()
    },
    methods: {
        updateMerchantInfo:async function(){
            try{
                this.isLoading=true;
                await this.$directus.items('Merchant').updateOne(this.Merchant.id, this.Merchant);
                await this.GetCods();
                this.isLoading=false;
            }catch(e){
                console.log(e)
            }
          
        },
        DelCashier:async function(id){
            this.isLoading=true;
            let res = await this.$directus.items('Cashier').deleteOne(id);
            this.isLoading=false;
            this.getCashiers()
        },
        getCashiers:async function(){
            this.isCashierLoading=true;
            try{
                let res = await this.$directus.items('Cashier').readByQuery({
                // By default API limits results to 100.
                // With -1, it will return all results, but it may lead to performance degradation
                // for large result sets.
                sort:['-date_created'],
                filter: {
                    Merchant: {

                       "_eq": this.Merchant.id
                    
                    }
                },
            });
        this.isCashierLoading=false;
        
       this.Cashiers = res.data
            }catch(e){
                console.log(e);
                this.isCashierLoading=false;
            }
            
        },
        restCashier:function(){
            this.Cashier={
                Name:"",
                Username:""
            }
        },
        SaveCashier:async function(){
            
            this.isLoading=true;
            let res = await this.$directus.items('Cashier').createOne({
	            Name: this.Cashier.Name,
                username:this.Cashier.Username,
                Merchant:{
                    id:this.Merchant.id
                }
        });
        this.isLoading=false;
        this.restCashier();
        this.getCashiers()
        console.log(res)
        },
        GetCods: async function () {
            this.isLoading = true;
            let user = await this.$directus.users.me.read();
            let res = await this.$directus.items('Merchant').readByQuery({
                // By default API limits results to 100.
                // With -1, it will return all results, but it may lead to performance degradation
                // for large result sets.
                filter: {
                    Owner: {

                        "_eq": user.id

                    }
                },
            });
            this.Merchant = res.data[0];
            this.isLoading = false;
            console.log(res.data[0]);
        }
    },
    data: function () {
        return {
            isCashierLoading:false,
            isLoading: false,
            Merchant: {},
            Cashiers:[],
            Cashier:{
                Name:"",
                Username:""
            }
        }
    }
}
</script>