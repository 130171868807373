import Vue from "vue";
import './plugins/axios'
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { Directus } from "@directus/sdk";
import VueApollo from "vue-apollo";
import 'swiper/css';
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
var infiniteScroll =  require('vue-infinite-scroll');
Vue.use(infiniteScroll)
const directus = new Directus("https://1mj7f7en.directus.app/", {
  auth: {
    mode: "json", // 'json' in Node.js
    autoRefresh: true,
    msRefreshBeforeExpires: 800000,
  },
});
Vue.config.productionTip = false;
Vue.prototype.$directus = directus;
import ApolloClient from "apollo-boost";

const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  uri: "https://1mj7f7en.directus.app/graphql",
  request: (operation) => {
    const token = window.localStorage.getItem("auth_token");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
});
import ARLang from '../src/assets/ar.js'
import EnLang from '../src/assets/en.js'
const messages = {
  en: EnLang,
  ar: ARLang
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'ja', // set locale
  messages, // set locale messages
})

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

new Vue({
  router,
  
  apolloProvider,
  vuetify,
  i18n,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
