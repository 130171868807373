<template>
<div>
    <v-row v-if="!isLoading"> 
        <v-col cols="12">
            <v-card :loading="isLoading"   outlined>
                
                        <v-card-title>Deal Details</v-card-title>
                        <v-card-subtitle class="font-weight-light ">View Details</v-card-subtitle>

                <v-divider></v-divider>
                <v-row class="pa-2 mx-3">
                    <v-col cols="8">
                        <h1 class=" subtitle-2-lg subtitle-1 font-weight-bold  "> {{Details.Offer_Type.Title_English}}</h1>
                        <p><span class="font-weight-thin">Deal Type</span></p>
                        
                    </v-col>
                    <v-col cols="4">
                        <h1 class=" subtitle-1 font-weight-bold  " :style="{color:Details.status === 'draft' ? 'orange' : 'green'}"> {{ Details.status === 'draft' ? 'Pending' : 'Published' }}</h1>
                        <p><span class="font-weight-thin">Status</span></p>
                        
                    </v-col>
                </v-row>
                <v-row class="pa-2 mx-3">
                    <v-col cols="8">
                        <h1 class=" subtitle-1 font-weight-bold  "> {{new Date(Details.date_created).toLocaleDateString()}}</h1>
                        <p><span class="font-weight-thin">Created Date</span></p>
                        
                    </v-col>
                    <v-col cols="4">
                        <h1 class=" subtitle-1 font-weight-bold  " > {{new Date(Details.Expiry_Date).toLocaleDateString()}}</h1>
                        <p><span class="font-weight-thin">Expiry Date</span></p>
                        
                    </v-col>
                </v-row>
                

            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
                <v-card-title>Channels</v-card-title>
                <v-card-subtitle class="font-weight-light">Active Channels</v-card-subtitle>
                <v-divider></v-divider>

                <v-row justify="center" class="mx-4 my-4">
                    <v-col cols="auto">
                        <v-chip-group
            active-class="primary--text"
            column
          >
            <v-chip
              v-for="tag in Details.Selected_Channels"
              outlined
            >
            <v-avatar left>
                <v-img :src="'https://1mj7f7en.directus.app/assets/'+tag.Channels_id.logo"></v-img>
            </v-avatar>
            {{tag.Channels_id.Title_English}}
            </v-chip>
          </v-chip-group>
                    </v-col>
                 
                </v-row>

            </v-card>
        </v-col>
      
                <v-col cols="12">
                <v-card  ripple dark @click="" tail color="red darken-4" class="pa-2">
                    
                      <v-card-title  class="white--text text-h5 font-weight-black">
                        <v-avatar left>
                        <v-icon class="mx-4" large>mdi-file-undo-outline</v-icon>
            </v-avatar>Unpublish Deal   </v-card-title>
                    <v-card-subtitle class="subtitle-1 white--text">Your Deal Will Be Unpublished From All Active Channels</v-card-subtitle>
                   
                    
                   
                  

                </v-card>
            </v-col>
           
        <v-col cols="12">
            <v-card outlined disabled>
                <v-card-title>Redeems</v-card-title>
                <v-card-subtitle class="font-weight-light caption">Reddems information For This Deal</v-card-subtitle>
                <v-divider></v-divider>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-img src="https://lh3.googleusercontent.com/ZRbzormInrqWNrjKjb21Rolv41Pm7psNuEOdkceeTRhZZljB-Lxgx6d3QA9oe1V-Kwus=w280-rwa"></v-img>

                    </v-col>
                </v-row>

            </v-card>
        </v-col>
    </v-row>
    <v-row v-if="isLoading"> 
       
        <v-col cols="12">
            <v-card :loading="isLoading"  outlined class="pa-1">
                <v-row>
                    <v-col cols="8">
                        <v-card-title>Deal Details</v-card-title>
                        <v-card-subtitle class="font-weight-light caption">View Details</v-card-subtitle>

                    </v-col>

                </v-row>
                <v-divider></v-divider>
                <v-row>
                    <v-col cols="6">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                </v-row>

            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card outlined>
                <v-card-title>Channels</v-card-title>
                <v-card-subtitle class="font-weight-light caption">Active Channels</v-card-subtitle>
                <v-divider></v-divider>

                <v-row justify="center" class="mt-1">
                    <v-col class="mx-2 px-5" cols="auto" v-for="n in 5">
                        
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="chip"></v-skeleton-loader>

                    </v-col>
                    <!-- <v-col class="mx-2 px-5" cols="auto">
                        <v-avatar @click="" color="indigo" size="50">
                            <v-icon color="white">mdi-home</v-icon>
                        </v-avatar>
                        <p class="text-center subtitle-2 text--secondary mt-2">View More</p>

                    </v-col> -->

                </v-row>

            </v-card>
        </v-col>
      
                <v-col cols="12">
                <v-card  ripple dark @click="" tail color="red darken-4" >
                  <v-row class="pa-4">
                  
                    <v-col cols="auto">
                      <v-card-title  class="white--text text-h4">   <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader> </v-card-title>
                    <v-card-subtitle class="font-weight-light subtitle-1 white--text">   <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader></v-card-subtitle>
                   
                    </v-col>
                   
                  </v-row>
                    
                   
                  

                </v-card>
            </v-col>
           
        <v-col cols="12">
            <v-card outlined loading disabled>
                <v-card-title>Redeems</v-card-title>
                <v-card-subtitle class="font-weight-light caption">Reddems information For This Deal</v-card-subtitle>
                <v-divider></v-divider>
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-img src="https://lh3.googleusercontent.com/ZRbzormInrqWNrjKjb21Rolv41Pm7psNuEOdkceeTRhZZljB-Lxgx6d3QA9oe1V-Kwus=w280-rwa"></v-img>

                    </v-col>
                </v-row>

            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
    props: ['id'],
    data: function () {
        return {
            isLoading: false,
            Details: {},
        }
    },
    watch: {
        id: async function (newV) {
            this.isLoading = true;
            this.id=newV
            this.Details={};
            console.log(newV)
            let res = await this.$directus.items('offers_demo').readByQuery({
                filter: {
                    id: {
                        '_eq': newV
                    }
                },
                limit: -1,
                fields:['*','Offer_Type.*','Selected_Channels.Channels_id.logo','Selected_Channels.Channels_id.Title_English','Selected_Channels.Channels_id.id'],

            });
            console.log(res.data[0])
            this.isLoading = false;
            this.Details = res.data[0]
        }
        
    },
    mounted: async function () {
        // this.isLoading = true;
        // let res = await this.$directus.items('offers_demo').readByQuery({
        //     limit: -1,

        // });

        // console.log(res.data)
    },

}
</script>
