<template>
<v-container >
   <div>
    <v-row dense class=" py-5 px-15" justify="space-between">
                        <h1 class=" text-h5 font-weight-black">Latest Redeem</h1>
                        <v-spacer></v-spacer> 
                        <v-btn  icon outlined :disabled="Page===1"  @click="CodePageDown" class="mx-1"><v-icon>mdi-chevron-left</v-icon></v-btn >
                            <v-btn  icon outlined :disabled="!desserts.length"  @click="CodePageUP" class="mx-1"><v-icon>mdi-chevron-right</v-icon></v-btn >
                    
                   </v-row>
                   <v-divider></v-divider>
        <v-row justify="center" class="my-5" v-if="desserts.length && !isLoading">
        <v-col cols="11" v-for="n in desserts">
            <v-card  outlined class="pa-5" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title" >#{{2500 + n.id}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Number</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title">{{n.Code}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Code</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light overline" >{{n.Offer_ID ? n.Offer_ID.Title_English : '#' }}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Offer</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title">{{n.Channel ? n.Channel.Title_English : '#' }}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Channel</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title">{{new Date(n.date_created).toLocaleDateString()}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Date</v-card-subtitle>
                    </v-col>
                    
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>
    <v-row justify="center" class="my-5" v-if="isLoading">
        <v-col cols="11" v-for="n in 5">
            <v-card  outlined class="pa-5" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>
   
  
    <v-row justify="center" class="my-5" v-if="isEnd">
        <v-col cols="auto">
            <h1>No More Redeems</h1>
        </v-col>
    </v-row>
  </div> 
</v-container>
</template>
<script>
export default{
    mounted:function(){
        this.GetCods();
    },
    methods:{
        CodePageUP:async function (){
            this.Page++;
            this.GetCods();
        },
        CodePageDown:async function (){
            this.Page--;
            this.GetCods();
        },
        getDealsByPage:async function(data){
            // this.isLoading=true;
            // console.log(data)
            // let res = await this.$directus.items('Merchant_Code').readByQuery({
            //     // By default API limits results to 100.
            //     // With -1, it will return all results, but it may lead to performance degradation
            //     // for large result sets.
            //     limit: -1,
            //     page:data.page,
            //     fields:['id','date_created','Code','Channel.id.Title_English','Channel.*','Redeemed','Offer_ID.Title_English','Offer_ID.id'],
            //     sort: ['-date_created']
            // });
            // this.desserts.concat(res.data)
            // this.isLoading=false;
        },
        GetCods:async function(){
            this.isLoading=true;
            this.isEnd=false;
            let res = await this.$directus.items('Merchant_Code').readByQuery({
             
                limit: 10,
                page:this.Page,
                fields:['id','date_created','Code','Channel.id.Title_English','Channel.*','Redeemed','Offer_ID.Title_English','Offer_ID.id'],
                sort: ['-date_created']
            });
            this.desserts=res.data;
            if(!res.data.length)
            this.isEnd=true;
            this.isLoading=false;
            console.log(res.data)
        }
    },
    data () {
      return {
        Page:1,
        isLoading:false,
        isEnd:false,
        headers: [
        { text: 'Offer', value: 'Offer_ID.Title_English' },
          { text: 'Code', value: 'Code' },
          { text: 'Date', value: 'date_created' },
          { text: 'Channel', value: 'Channel' },
          { text: 'Cashier',  },
         
        ],
        desserts: [],
      }
    },
  
}
</script>