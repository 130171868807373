<template>
<v-container  >
    <v-row dense class=" py-5" justify="space-between">
                        <h1 class=" text-h5 font-weight-black">Deal Templates</h1>
                        <v-spacer></v-spacer> 
                        <v-btn  icon outlined disabled class="mx-1"><v-icon>mdi-chevron-left</v-icon></v-btn >
                            <v-btn  icon outlined disabled class="mx-1"><v-icon>mdi-chevron-right</v-icon></v-btn >
                    
                   </v-row>
                   <v-row>
                    <v-col cols="3">
              <v-card outlined  style="width:100%" class="px-3 py-2">
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="100">
                            <v-icon large>mdi-shopping-outline</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
             <v-card-title>All Time Deal</v-card-title>
             <v-card-subtitle>Two All-Time Deals Are Required To Maintain Your Profile On Neo Engine Platform  </v-card-subtitle>
             <v-card-actions>
                <v-btn block outlined to="/deals/ATD" >Create</v-btn>
             </v-card-actions>
              </v-card>
          </v-col>
          <v-col cols="3">
              <v-card outlined  style="width:100%" class="px-3 py-2">
                <v-row justify="center">
                    <v-col cols="auto">
                        
                        <v-avatar color="white" size="100">
                            <v-icon large>mdi-calendar-month</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Time Based Deal</v-card-title>
                <v-card-subtitle>Publish Deals For Specific Channels With An Expiry Date And Cap Limit  </v-card-subtitle>
             <v-card-actions>
                <v-btn block to="/deals/TBD" outlined >Create</v-btn>
             </v-card-actions>
              </v-card>
          </v-col>
          <v-col cols="3">
              <v-card outlined disabled style="width:100%" class="px-3 py-2">
                <v-row justify="center">
                    <v-col cols="auto">
                        <v-avatar color="white" size="100">
                            <v-icon large>mdi-party-popper</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Campaign Based Deal</v-card-title>
                <v-card-subtitle>Publish Deals For Marketing Campaign By Neo Engine that Expires When The Campaign Ends   </v-card-subtitle>
             <v-card-actions>
                <v-btn block disabled >Locked</v-btn>
             </v-card-actions>
              </v-card>
          </v-col>
          <v-col cols="3">
              <v-card outlined disabled style="width:100%" class="px-3 py-2">
                <v-row justify="center">
                    <v-col cols="auto">
                       
                        <v-avatar color="white" size="100">
                            <v-icon large>mdi-wallet-plus-outline</v-icon>
                        </v-avatar>
                    </v-col>
                </v-row>
                <v-card-title>Cash Back Deals</v-card-title>
                <v-card-subtitle>Launch Your Managed Loyalty Wallet Across Channels</v-card-subtitle>
             <v-card-actions>
                <v-btn block  disabled >Locked</v-btn>
             </v-card-actions>
              </v-card>
          </v-col>
                   </v-row>
                   <v-row dense class=" py-5" justify="space-between">
                        <h1 class=" text-h5 font-weight-black">Latest Deals</h1>
                        <v-spacer></v-spacer> 
                        <v-btn  icon outlined @click="DealsPageDown" :disabled="DealsPage === 1" class="mx-1"><v-icon>mdi-chevron-left</v-icon></v-btn >
                            <v-btn  icon @click="DealsPageUP" :disabled="!Deals.length" outlined class="mx-1"><v-icon>mdi-chevron-right</v-icon></v-btn >
                    
                   </v-row>

                   <v-row justify="center" class="my-5" v-if="Deals.length && !DealsLoading">
        <v-col cols="12" v-for="n in Deals">
            <v-card  outlined class="pa-3" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title" >#{{2500 + n.id}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Number</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-bold title" :style="{color:n.status === 'draft' ? 'orange' : 'green'}"> {{ n.status === 'draft' ? 'Pending' : 'Published' }}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Status</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light overline" >{{n.Offer_Type.Title_English}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Offer</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title">{{n.Template === 'TBD' ? new Date(n.Expiry_Date).toLocaleDateString():'All Time Deal'}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Expiry</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title">{{new Date(n.date_created).toLocaleDateString()}}</v-card-title>
                        <v-card-subtitle class="font-weight-light">Date Created</v-card-subtitle>
                    </v-col>
                    <v-col cols="2">
                        <v-card-title class="font-weight-light title mt-3"><v-btn :to="'/deal/'+n.id" text>View Details</v-btn></v-card-title>
                       
                    </v-col>
                    
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>

    <v-row justify="center" class="my-5" v-if="DealsLoading">
        <v-col cols="11" v-for="n in 5">
            <v-card  outlined class="pa-5" >
                <v-row justify="space-between" >
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="2">
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                        <v-skeleton-loader class="mx-auto my-3" max-width="300" type="text"></v-skeleton-loader>
                    </v-col>
                    
                </v-row>
                
            </v-card>
        </v-col>
    </v-row>
    <v-row justify="center" class="my-5" v-if="noresults && !Deals.length">
        <v-col cols="auto" >
           <h1>No Deals Found</h1>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import NewDeal from './NewDeal.vue';
import DealsDetails from './DealsDetails.vue';
export default {
    mounted: function () {
        this.isNewDealSelected = true;
        this.selectedDealID = -1;
        this.getDeals();

    },
    components: {
        newDeal: NewDeal,
        dealsDetails: DealsDetails
    },
    methods: {
        Reload: function () {
            this.isNewDealSelected = true;
            this.selectedDealID = null;
            this.DealsLoading = true;
            this.page = 1;
            this.Deals = [];
            this.getDeals();
        },
        loadMore: async function () {
            this.busy = true;
            let self = this;
            this.page++;
            console.log(this.page)

            if (!this.isFinishedres) {
                console.log("Geeting Data")
                let res = await this.$directus.items('offers_demo').readByQuery({
                    // By default API limits results to 100.
                    // With -1, it will return all results, but it may lead to performance degradation
                    // for large result sets.
                    limit: 5,
                    fields: ['id', 'date_created', 'Offer_Type.Title_English', 'Offer_Type.Title_Arabic', 'Expiry_Date', 'status', 'Selected_Channels'],
                    page: this.page,
                    sort: ['-date_created']
                });
                if (res.data.length)
                    this.isFinishedres = true

                res.data.forEach(function (el) {
                    self.Deals.push(el)
                })
            }

            this.busy = false;

        },
        DealsPageUP:function(){
            this.DealsPage++;
            this.getDeals();
        },
        DealsPageDown:function(){
            this.DealsPage--;
            this.getDeals();
        },
        getDeals: async function () {
            this.DealsLoading=true;
            this.noresults = false;
            let res = await this.$directus.items('offer_test').readByQuery({
                // By default API limits results to 100.
                // With -1, it will return all results, but it may lead to performance degradation
                // for large result sets.
                limit: 5,
                page: this.DealsPage,
                fields: ['id', 'date_created', 'Offer_Type.Title_English', 'Offer_Type.Title_Arabic','Template', 'Expiry_Date', 'status', 'Selected_Channels'],
                sort: ['-date_created']
            });
            this.Deals = res.data;
            this.DealsLoading = false;
            console.log(res)
            if (!res.data.length)
                this.noresults = true;
            this.selectedDealID = res.data[0].id;
            console.log(this.selectedDealID)
            this.DealsLoading = false;
        },
        getdeal: async function () {
            console.log("test")

        },
        addNewDeal: function () {
            this.isNewDealSelected = true;
        },
        getDealDetail: function (id) {

            this.selectedDealID = id;
            this.isNewDealSelected = false;

        }

    },
    watch:{
        selectedDealID:function(newV){
            console.log(newV)
            let self=this;
            if(newV == -1) 
             
            {
                self.isNewDealSelected = true;
            }else{
                self.selectedDealID = newV;
                self.isNewDealSelected = false;
            }
         
        },
    },
    data: function () {
        return {
            Deals: [],
            page: 1,
            DealsPage:1,
            busy: false,
            isFinishedres: false,
            isNewDealSelected: false,
            selectedDealID: -1,
            DealsLoading: true,
            noresults: false,
        }
    }
}
</script>
