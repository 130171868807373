<template>
<div>
    <v-container fluid>
      
            

                <v-row justify="start">
                    <v-col xl="3" lg="6" md="12" sm="12" xs="12">
                        <v-card  class="pa-5 "  color="black">
                            <v-row align="center" style="height:100%;">
                                 <v-col cols="1" class="py-5 mx-1">

                                <v-avatar color="white" size="80">
                                       <v-icon size="45" color="orange">mdi-star</v-icon>
                                    </v-avatar>

                            </v-col>
                        <v-col cols="auto">
                            <v-list-item>
                                    <v-list-item-avatar>
                                      
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="orange--text text-h3 font-weight-black"> {{Org.Points ? Org.Points : '+100'}}</v-list-item-title>
                                        <v-list-item-subtitle class=" white--text font-weight-medium">Your Points</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                        </v-col>
                        
                        </v-row>
                          
                        </v-card>
                    </v-col>
                    <v-col xl="3" lg="6" md="12" sm="12" xs="12">
                        <v-card  class="pa-5 "  color="black">
                            <v-row align="center" style="height:100%;"> <v-col cols="1" class="py-5 mx-1">

                                <v-avatar color="white" size="80">
                                       <v-icon size="45" color="teal">mdi-offer</v-icon>
                                    </v-avatar>

                            </v-col>
                        <v-col cols="auto">
                            <v-list-item>
                                    <v-list-item-avatar>
                                      
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="teal--text text-h3 font-weight-black"> {{ActiveDeals}}</v-list-item-title>
                                        <v-list-item-subtitle class=" white--text font-weight-medium">Active Deals</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                        </v-col>
                        
                        </v-row>
                          
                        </v-card>
                     
                    </v-col>
                    <v-col xl="3" lg="6" md="12" sm="12" xs="12">
                        <v-card  class="pa-5 "  color="black">
                            <v-row align="center" style="height:100%;"> <v-col cols="1" class="py-5 mx-1">

                                <v-avatar color="white" size="80">
                                       <v-icon size="45" color="indigo">mdi-trophy</v-icon>
                                    </v-avatar>

                            </v-col>
                        <v-col cols="auto">
                            <v-list-item>
                                    <v-list-item-avatar>
                                      
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="indigo--text text-h3 font-weight-black"> {{Org.Level ? Org.Level : '1'}}</v-list-item-title>
                                        <v-list-item-subtitle class=" white--text font-weight-medium">Your Level</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                        </v-col>
                        
                        </v-row>
                          
                        </v-card>
                      
                    </v-col>
                    <v-col xl="3" lg="6" md="12" sm="12" xs="12">
                        <v-card  class="pa-5 "  color="black">
                            <v-row align="center" style="height:100%;"> <v-col cols="1" class="py-5 mx-1">

                                <v-avatar color="white" size="80">
                                       <v-icon size="45" color="purple">mdi-shimmer</v-icon>
                                    </v-avatar>

                            </v-col>
                        <v-col cols="auto">
                            <v-list-item>
                                    <v-list-item-avatar>
                                      
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="purple--text text-h3 font-weight-black">200</v-list-item-title>
                                        <v-list-item-subtitle class=" white--text font-weight-medium">All Deals</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                        </v-col>
                        
                        </v-row>
                          
                        </v-card>
                       
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col xl="6" lg="6" md="12" sm="12" xs="12">
                        <v-card outlined :loading="isDealsLoading">
                            <v-row dense class=" pa-5" justify="space-between">
                        <h1 class=" text-h5 font-weight-light">Deals</h1>
                        <v-spacer></v-spacer>
                       

                    </v-row>
                           
                            <v-divider></v-divider>
                            <v-card-text style="height:60vh;overflow-y: scroll;">
                                <v-list three-line>
                                    <template v-for="(item, index) in Deals">

                                        <v-list-item @click="" :to="'/deal/'+item.id">
                                            <v-list-item-avatar size="50">
                                                <v-avatar outlined color="teal" v-if="item.Template === 'ATD'">
                                                    <v-icon color="white">mdi-shopping-outline</v-icon>
                                                </v-avatar>
                                                <v-avatar outlined color="teal" v-if="item.Template === 'TBD'">
                                                    <v-icon color="white">mdi-calendar-month</v-icon>
                                                </v-avatar>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title class="font-weight-bold">{{ item.Offer_Type.Title_English }}</v-list-item-title>
                                                <v-list-item-subtitle>Date Created : {{ new Date (item.date_created).toLocaleDateString() }}</v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="item.status === 'draft'">Status : <span class="orange--text  font-weight-black">Pending</span></v-list-item-subtitle>
                                                <v-list-item-subtitle v-if="item.status === 'published'">Status : <span class="green--text  font-weight-black">Published</span></v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </template>
                                    <v-list-item @click="">
                                        <v-list-item-avatar size="50">
                                            <v-avatar outlined color="black">
                                                <v-icon color="white">mdi-home</v-icon>
                                            </v-avatar>

                                        </v-list-item-avatar>

                                        <v-list-item-content>
                                            <v-list-item-title class="font-weight-bold">View All Deals</v-list-item-title>
                                            <v-list-item-subtitle>View All Deals Available </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider></v-divider>
                                </v-list>

                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col xl="6" lg="6" md="12" sm="12" xs="12">
                        <v-card outlined :loading="isChannelLoading">
                    <v-row dense class=" pa-5" justify="space-between">
                        <h1 class=" text-h5 font-weight-light">Channels</h1>
                        <v-spacer></v-spacer>
                        <v-btn small @click="ChannelPageDown" :loading="isChannelLoading" :disabled="ChannelPage === 1" icon outlined class="mx-1">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn small @click="ChannelPageUP" :loading="isChannelLoading" :disabled="!Channels.length" icon outlined class="mx-1">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>

                    </v-row>
                    <v-divider></v-divider>
                    <v-card-text style="height:58vh;overflow-y: scroll;">
                        <v-list two-line>
                            <template v-for="(item, index) in Channels">

                                <v-list-item @click="" :to="'/school/'+item.id">
                                    <v-list-item-avatar>
                                        <v-img cover :src="'https://1mj7f7en.directus.app/assets/'+item.logo"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-html="item.Title_English"></v-list-item-title>
                                        <v-list-item-subtitle>Amman , Jordan</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
                    </v-col>
                </v-row>
        
     
    </v-container>
</div>
</template>

<script>
export default {
    mounted: async function () {
        this.isLoading = true;
        await this.getDeals();
        await this.getChannels();
        await this.getOrg();
        await this.GetCods()
        this.isLoading = false;
    },
    methods: {
        getOrg: async function () {

            this.User = await this.$directus.users.me.read();
            let res = await this.$directus.items('Merchant').readByQuery({
                filter: {
                    Owner: {
                        id: {
                            _eq: this.User.id
                        }
                    }
                }
            });
            this.Org = res.data[0];
            console.log(res.data)
            window.localStorage.setItem('Org', JSON.stringify(res.data[0]))

        },
        GetCods:async function(){
            let res = await this.$directus.items('offer_test').readByQuery({
                aggregate:{
                    countDistinct:'id'
                },
                filter:{
                    status:{
                        _eq:'published'
                    }
                },
            });
           this.ActiveDeals=res.data[0].countDistinct.id;
            
        },
        getDeals: async function () {
            this.isDealsLoading=true;
            let res = await this.$directus.items('offer_test').readByQuery({
                limit: 5,
                
                fields: ['*', 'Offer_Type.*'],
                sort: ['-date_created']
            });
            this.Deals = res.data
            this.isDealsLoading=false;
        },
        ChannelPageDown: function () {
            this.ChannelPage--;
            this.getChannels();
        },
        ChannelPageUP: function () {
            this.ChannelPage++;
            this.getChannels();
        },
        getChannels: async function () {
            this.isChannelLoading=true;
            let res = await this.$directus.items('Channels').readByQuery({
                limit: 15,

                page: this.ChannelPage,
                sort: ['-Title_English']
            });
            this.Channels = res.data;
            this.isChannelLoading=false;
            console.log(res.data)

        }
    },
    data: function () {
        return {
            isLoading: false,
            ChannelPage: 1,
            isChannelLoading:true,
            isDealsLoading:true,
            ActiveDeals:0,
            User: {},
            Org: {},
            Deals: [],
            Redeems:[],
            Channels: []
        }
    }
}
</script>

<style>
.enabled {

    color: #121212 !important;
    background-color: black !important;
    font-weight: 500;
}
</style>
